import React, { useEffect, useRef, useState } from 'react';
import loc from './css/about-us-vision.module.css';
import styles from './css/subpages.module.css';
import arrows from './images/down-arrows-navyblue.png';
import Header from './components/header';
import { useTransitionHistory } from 'react-route-transition';
import { Helmet } from 'react-helmet'; // Import Helmet
//import Group from './images/about-us-group.jpg'
import getData from './utils/fetchdata'
import bc from './css/breadcrumb.module.css'
import { useMediaQuery } from 'react-responsive';

const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const [state, setState] = useState(
    {
    	titleL: '', subtitleL:'', contentTitleL:'', contentL:'', sliderF:'', lastEntry: ''
    }
)
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

function handleKeyDown(e){
	if(e.keyCode === 37){
		e.preventDefault(); //left arrow
    	//history.push('/about-us-strategy');
	}
	else if(e.keyCode === 38){ //Up arrow
		e.preventDefault();
    	history.push('/about-us-strategy');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push(`/${state.year}`);
	}
}

 function handleDown(e) {
  e.preventDefault();
	history.push(`/chart`);
}

 function handleUp(e) {
  e.preventDefault();
  history.push(`/about-us-vision`);
}

useEffect(() => {
  mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
const fetchData = async () => {
  const resp2 = await getData(`${process.env.PUBLIC_URL}/apidata/turnover.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let arr = []
      let years = []
      data.forEach(x => 
        years.push(x.acf.year)
      )

      data.forEach(x => 
        arr.push({...x.acf, years: years})
      )
      return arr[arr.length - 1]
    }
  })

   const resp3 = await getData(`${process.env.PUBLIC_URL}/apidata/generaldata.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      //console.log(data[0].acf.bmt_group_structure_image.url)
      return data[0].acf.bmt_group_structure_image.url && data[0].acf.bmt_group_structure_image.url
    }
  })

  setState({...resp2, groupStructure: resp3})
}

fetchData();
}, []);

return(
<>
    <Helmet>
        <meta name="description" content="We house several brands: IGW, VCST, OMCO and BMT aerospace." />
    </Helmet>
<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" style={{position: "relative", overflow: "hidden"}}>
<div className={`${loc.fullpage} d-flex flex-column`}>
	<Header />

  { isDesktop &&
  <div className={`${bc.vertical2}`}> 
  <a href="/history">History / </a>
  <a href="/bmt-global-presence">Global Presence / </a>
  <a href={`/${state.year}`}>Turnover / </a>
  <span className={bc.destination}>Group Structure / </span>
  <a href="/about-us-vision">Vision </a>
  </div>
  }

  <a href='/about-us-vision' onClick={(e) => handleUp(e)}>
    <img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)", height: "25px"}} alt="bmt aerospace arrows"/>
  </a>

	<div className={`container-fluid ${loc.container}`}>
		<div className="row align-items-center" style={{marginTop: '100px'}}>
			<div className={`col ${loc.colMobile} d-flex flex-column justify-content-center align-items-center`}>
				<h1 className={`${loc.title} ${loc.titleContainer} ${styles.blue}`} style={{marginRight: "0px", marginLeft: "0px", marginTop: "1.5rem"}}>BMT Group Structure</h1>
			</div>
		</div>
		<img className="img-fluid d-block mx-auto mt-3 mb-2" src={state.groupStructure && state.groupStructure} style={{maxHeight: "60vh"}} alt="bmt group"/>
	</div>

 	{/*<div className="col-md-12 d-flex align-items-center justify-content-center mt-auto mb-2">
		<a href={`/${state.year}`} onClick={(e) => handleDown(e)}>
			<img src={arrows} className={styles.downArrows} alt="down arrows" style={{height: "25px"}}/>		
		</a>
	</div>*/}

  <div className="col-md-12 d-flex align-items-center justify-content-center mt-auto mb-3">
    <a href={`/chart`} onClick={(e) => handleDown(e)}>
      <img src={arrows} alt="down arrows" style={{height: "25px"}}/>    
    </a>
  </div>
</div>
</div>
</>
)}

export default Page