import React, { useEffect, useRef, useState } from 'react';
import loc from './css/bmt-groups.module.css'
//import home from './css/home.module.css'
import custom from './css/fivecolumns.module.css'
import Header from './components/header'
import { Helmet } from 'react-helmet'; // Import Helmet
//import logo from './images/BMT_GROUP_logo_white.png'
//import drivesolutions from './images/bmt-drive-solutions-768x173.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import { useMediaQuery } from 'react-responsive';


const Page = () => {
    const mainRef = useRef(null);
    const history = useTransitionHistory();

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        bmt_aerospace_logoF: "",
        bmt_drive_logo: "",
        omco_logoF: "",
        bmt_aerospace_descr: "",
        bmt_drive_descr: "",
        omco_descr: "",
        aerospaceAlt: "",
        driveAlt: "",
        omcoAlt: "",
        igw_logoF: "",
        igwi_logoF: "",
        vest_logoF: ""
    });

    const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

    useEffect(() => {
        const fetchData = async () => {
            await getData(`${process.env.PUBLIC_URL}/apidata/generaldata.json`).then(
                (data) => {
                    if (Array.isArray(data) && data.length) {
                        data.forEach((x) => {
                            const {
                                bmt_aerospace_logo,
                                bmt_drive_solutions_logo,
                                omco_logo,
                                bmt_drive_solutions_description,
                                omco_description,
                                bmt_aerospace_description,
                                vest_logo,
                                igw_logo,
                            } = x.acf;
                            let igwi_logo = x.acf["igw-i_logo"]
                            setState({
                                bmt_aerospace_logoF: `/images/${
                                    bmt_aerospace_logo.url.split("wp-content/")[1]
                                }`,
                                aerospaceAlt: bmt_aerospace_logo.alt,
                                bmt_drive_logo: `/images/${
                                    bmt_drive_solutions_logo.url.split("wp-content/")[1]
                                }`,
                                driveAlt: bmt_drive_solutions_logo.alt,
                                omco_logoF: `/images/${omco_logo.url.split("wp-content/")[1]}`,
                                omcoAlt: omco_logo.alt,
                                bmt_aerospace_descr: bmt_aerospace_description,
                                bmt_drive_descr: bmt_drive_solutions_description,
                                omco_descr: omco_description,
                                vest_logoF: `/images/${vest_logo.url.split("wp-content/")[1]}`,
                                igw_logoF: `/images/${igw_logo.url.split("wp-content/")[1]}`,
                                igwi_logoF: `/images/${igwi_logo.url.split("wp-content/")[1]}`
                            });
                        });
                        setLoading(true);
                    }
                }
            );
        };
        fetchData();
    }, []);

    function handleKeyUp(e) {
        e.preventDefault();
        if (e.keyCode === 38) {
            //Up arrow
            history.push("/");
        } else if (e.keyCode === 40) {
            //down arrow
            //history.push("/bmt-drive-solutions");
        }
    }

    function toLink(link)
    {
        history.push(link)
    }

    useEffect(() => {
    let col1 = document.querySelector("#firstCard")
    let tl1 = gsap.timeline()
    .to("#secondLogo", {rotate: 90, duration: 0}, 0)
    .to("#thirdLogo", {rotate: 90, duration: 0}, 0)
    .to("#forthLogo", {rotate: 90, duration: 0}, 0)
    .to("#fifthLogo", {rotate: 90, duration: 0}, 0)
    //.to("#hoverino", {display: "none", duration: 0}, 0)

    if(col1 && tl1)
    {
        tl1.reverse()
        col1.addEventListener("mouseenter", () => tl1.play());
        col1.addEventListener("mouseleave", () => tl1.reverse());
    }

    let col2 = document.querySelector("#secondCard")
    let tl2 = gsap.timeline()
    .to("#firstLogo", {rotate: 90, duration: 0}, 0)
    .to("#thirdLogo", {rotate: 90, duration: 0}, 0)
    .to("#forthLogo", {rotate: 90, duration: 0}, 0)
    .to("#fifthLogo", {rotate: 90, duration: 0}, 0)
    //.to("#hoverino", {width: "80%", duration: 0}, 0)

    if(col2 && tl2)
    {
        tl2.reverse()
        col2.addEventListener("mouseenter", () => tl2.play());
        col2.addEventListener("mouseleave", () => tl2.reverse());
    }

    let col3 = document.querySelector("#thirdCard")
    let tl3 = gsap.timeline()
    .to("#firstLogo", {rotate: 90, duration: 0}, 0)
    .to("#secondLogo", {rotate: 90, duration: 0}, 0)
    .to("#forthLogo", {rotate: 90, duration: 0}, 0)
    .to("#fifthLogo", {rotate: 90, duration: 0}, 0)
    //.to("#hoverino", {width: "80%", duration: 0}, 0)
    
    if(col3 && tl3)
    {
        tl3.reverse()
        col3.addEventListener("mouseenter", () => tl3.play());
        col3.addEventListener("mouseleave", () => tl3.reverse());
    }


    let col5 = document.querySelector("#fifthCard")
    let tl5 = gsap.timeline()
    .to("#firstLogo", {rotate: 90, duration: 0}, 0)
    .to("#secondLogo", {rotate: 90, duration: 0}, 0)
    .to("#forthLogo", {rotate: 90, duration: 0}, 0)
    .to("#thirdLogo", {rotate: 90, duration: 0}, 0)
    //.to("#hoverino", {display: "none", duration: 0}, 0)
    
    if(col5 && tl5)
    {
        tl5.reverse()
        col5.addEventListener("mouseenter", () => tl5.play());
        col5.addEventListener("mouseleave", () => tl5.reverse());
    }

    }, [loading]);

return (
<>
    <Helmet>
        <meta name="description" content="BMT Group is a family-owned company that invests in leading high-precision machining businesses. Its brands include IGW , VCST, OMCO and BMT Aerospace. BMT Group is committed to operational excellence, providing high-quality products in diverse industries." />
    </Helmet>
<Header/>

<div
    className={`container-fluid d-flex flex-column ${loc.containerHeight} no-gutters`} 
    style={{padding: '0px'}}
    ref={mainRef}
    onKeyDown={handleKeyUp}
    tabIndex="0"
>  
{/*<div className={`${custom.hoverino}`} id="hoverino">
    <div className="">
        <img className="mx-auto d-block" src={drivesolutions} 
        style={{maxWidth: "20%", marginTop: "85px"}}/>
    </div>
    <hr style={{border: "1px solid white", marginTop: "10px", zIndex: "2"}}/>
</div>*/}

    { isDesktop &&
    <div className={`${custom.container}`}>
      <div className={`${custom.card}`} id="firstCard">
        <a href="/bmt-aerospace-page">
            <div className={`${custom.vvv1}`}/>
            <div className={`${custom.card__head}`}>
            { state.bmt_aerospace_logoF &&
                <img className={`${custom.logo}`} src={state.bmt_aerospace_logoF} alt={state.bmt_aerospace_logoF.alt && state.bmt_aerospace_logoF.alt} id="firstLogo"/>
            }
            </div>
            <div className={`${custom.arrowHead}`}/>
        </a>
      </div>
      <div className={`${custom.card}`} id="secondCard">
        <a href="/vcst">
            <div className={`${custom.vvv2}`}/>
            <div className={`${custom.card__head}`}>
            { state.vest_logoF && 
                <img className={`${custom.logo}`} src={state.vest_logoF} alt={state.vest_logoF.alt && state.vest_logoF.alt} id="secondLogo"/>
            }
            </div>
            <div className={`${custom.arrowHeadBordeau}`}/>
        </a>
      </div>
      <div className={`${custom.card}`} id="thirdCard">
        <a href="/igwi-page">
            <div className={`${custom.vvv3}`}/>
            <div className={`${custom.card__head}`}>
            { state.igwi_logoF &&
                <img className={`${custom.logo}`} src={state.igwi_logoF} alt={state.igwi_logoF.alt && state.igwi_logoF.alt} id="thirdLogo"/>
            }
            </div>
            <div className={`${custom.arrowHeadYellow}`}/>
        </a>
      </div>

      <div className={`${custom.card}`} id="fifthCard">
        <a href="/bmt-omco-page">
            <div className={`${custom.vvv5}`}/>
            <div className={`${custom.card__head}`}>
            { state.omco_logoF &&
                <img className={`${custom.logo}`} src={state.omco_logoF} alt={state.omco_logoF.alt && state.omco_logoF.alt} id="fifthLogo"/>
            }
            </div>
            <div className={`${custom.arrowHeadBlue}`}/>
        </a>
      </div>
    </div>
    }

    {
    !isDesktop &&
    
    <div className="container-fluid" style={{marginTop: "56px"}}>
        <div className={`row d-flex flex-column align-items-center ${custom.mobileRow1}`}
        onClick={() => toLink('/bmt-aerospace-page')}>
            { state.bmt_aerospace_logoF &&
                <img className={`${custom.logoMobile}`} src={state.bmt_aerospace_logoF} alt={state.bmt_aerospace_logoF.alt && state.bmt_aerospace_logoF.alt}/>
            }
            <div className={`${custom.arrowHeadMobile}`}/>
        </div>
        <div className={`row d-flex flex-column align-items-center ${custom.mobileRow2}`}
        onClick={() => toLink('/vcst')}>
            { state.vest_logoF &&
                <img className={`${custom.logoMobile}`} src={state.vest_logoF} alt={state.vest_logoF.alt && state.vest_logoF.alt}/>
            }
            <div className={`${custom.arrowHeadBordeauMobile}`}/>
        </div>
        <div className={`row d-flex flex-column align-items-center ${custom.mobileRow3}`}
        onClick={() => toLink('/igwi-page')}>
            { state.igwi_logoF &&
                <img className={`${custom.logoMobile}`} src={state.igwi_logoF} alt={state.igwi_logoF.alt && state.igwi_logoF.alt}/>
            }
            <div className={`${custom.arrowHeadYellowMobile}`}/>
        </div>
        <div className={`row d-flex flex-column align-items-center ${custom.mobileRow5}`}
        onClick={() => toLink('/bmt-omco-page')}>
            { state.omco_logoF &&
                <img className={`${custom.logoMobile}`} src={state.omco_logoF} alt={state.omco_logoF.alt && state.omco_logoF.alt}/>
            }
            <div className={`${custom.arrowHeadBlueMobile}`}/>               
        </div>
    </div>
    }
</div>
</>
    );
};

export default Page;