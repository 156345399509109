import React, {useState, useEffect} from 'react';
import styles from '../css/footer.module.css'
import getData from '../utils/fetchdata'
import { useMediaQuery } from 'react-responsive';

const Footer = (props) => {

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      name: '', streetF: '', streetno:'', zip:'', cityF:'', countryF:'', emailF:'',
      phoneF:'', buttonF:''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 601px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/contact.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {company_name, street, street_number, zipcode, city, country, phone, email, contact_button} = x.acf
		if(x.title.rendered === "Contact"){
			setState({
				name: company_name,
				streetF: street,
				streetno: street_number,
				zip: zipcode,
				cityF: city,
				countryF: country,
				phoneF: phone,
				emailF: email,
				buttonF: contact_button
				})
			}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

return(
<>
{ loading &&
<>
{isDesktop && 
<div className={`${props.color === "white" ? styles.sectionBackgroundWhite : styles.sectionBackgroundDark} container-fluid`}>
	<div className={`row ${styles.footerWrapper} ${styles.toColumn}`}>
		<div className={`col ml-2 mr-2 d-flex flex-column justify-content-center align-items-left mt-5 mb-5 ${styles.footerContent}`}
		style={{color: props.color === "white" ? "#0073AB" : "white"}}>
			<div className={styles.contentBlock}>
				<div>{state.name}</div>
				<div className="mt-2">{state.streetno}, {state.streetF}</div>
				<div className="mt-2">{state.zip} {state.cityF}, {state.countryF}</div>
			</div>
		</div>

		<div className={`col ml-2 mr-2 d-flex flex-column justify-content-center align-items-left mt-5 mb-5 ${styles.footerContent}`}
		style={{color: props.color === "white" ? "#0073AB" : "white"}}>
			<div className={styles.contentBlock}>
				<div><a style={{color: props.color === "white" ? "#0073AB" : "white", textDecoration: "none"}} href={`tel: 32 50 590208`}>T: +32 50 590208</a></div>
				<div className="mt-2"><a style={{color: props.color === "white" ? "#0073AB" : "white", textDecoration: "none"}} href={`mailto:${state.emailF}`}>E: {state.emailF}</a></div>
				<div className="mt-2"><a style={{color: props.color === "white" ? "#0073AB" : "white", textDecoration: "none"}} href="/privacy" className={styles.link}>Privacy Statement</a></div>
				<div className="mt-2"><a style={{color: props.color === "white" ? "#0073AB" : "white", textDecoration: "none"}} href="https://bmt.integrityline.com/" className={styles.link}>Code of conduct</a></div>
			</div>
		</div>
		<div className="col ml-2 d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
			<div>
			{	
				state.buttonF ?
				<a href={state.buttonF.link}>
					<button className={`${styles.buttonText} ${props.color === "white" ? styles.buttonulReverse : styles.buttonul}`}>{state.buttonF.content}</button>
				</a>:null
			}
			</div>
		</div>
	</div>
</div>
}

{
	!isDesktop &&
	<>
	<div className={`${props.color === "white" ? styles.sectionBackgroundWhite : styles.sectionBackgroundDark} ${styles.white} container-fluid`}
		style={{paddingLeft: "30px", paddingRight: "30px"}}
	>
		<div className={`row pt-3`}>
			<div className={`col ${styles.companyName}`} style={{color: props.color === "white" ? "#0073AB" : "white"}}>
				BMT INTERNATIONAL N.V.
			</div>
		</div>
		<div className={`row`}>
			<div className={`col ${styles.companyAddress}`} style={{color: props.color === "white" ? "#0073AB" : "white"}}>
				6, Reigerlostraat
			</div>
		</div>
		<div className={`row ${styles.companyAddress}`} style={{color: props.color === "white" ? "#0073AB" : "white"}}>
			<div className="col">
				8730 Beernem, Belgium
			</div>
		</div>

		<div className={`row mt-2 pb-3`}>
			<div className="col pl-0 pr-0">
				<div className={`col ${styles.companyAddress}`} style={{color: props.color === "white" ? "#0073AB" : "white"}}>
					<span style={{fontWeight: "800"}}>T:</span> <a className={styles.companyAddress} style={{color: props.color === "white" ? "#0073AB" : "white"}} href="tel:+32 (0)50 590 208">+32 (0)50 590 208</a>
				</div>
				<div className={`col ${styles.companyAddress}`} style={{color: props.color === "white" ? "#0073AB" : "white"}}>
					<span style={{fontWeight: "800"}}>E:</span> <a className={styles.companyAddress} style={{color: props.color === "white" ? "#0073AB" : "white"}} href="mailto:info@bmt.be">info@bmt.be</a>
				</div>
			</div>
			<div className="col pl-0 pr-0 d-flex justify-content-center align-items-center" style={{marginLeft: "-3px"}}>
			{	
				state.buttonF &&
				<a href={state.buttonF.link}>	
					<button type="button" className={styles.buttonText} style={{color: props.color === "white" ? "white" : "#0073AB", backgroundColor: props.color === "white" ? "#0073AB" : "white"}}>Contact us</button>
				</a>
			}
			</div>
		</div>	
	
	</div>
	</>
}

</>
}
</>

)}

export default Footer;