import React, { useEffect, useRef, useState }  from 'react'
import styles from './css/subpages.module.css'
import map from './css/maps.module.css'
import './css/svg-styling.css'
import arrows from './images/down-arrows-navyblue.png'
import gsap from 'gsap'
import { useTransition, useTransitionHistory } from 'react-route-transition';
import Globe from './globalpresencemap'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import getData from './utils/fetchdata'
//import styled from 'styled-components'
import bc from './css/breadcrumb.module.css'

/*const entries = [
	{color: "blue", country: "Slovenija", company: "OMCO Metals Slovenia", street: "C. Zalskega tabora 10", city: "3310 Zalec", phones: ["T +386 37 131 300", "F +386 35 717 292"], email: ""},
	{color: "blue", country: "Belgium - Aalter", company: "OMCO International", street: "Venecolaan 10", city: "9880 Aalter", phones: ["T +32 9 374 20 05", "F +32 9 374 34 69"], email: ""},
	{color: "blue", country: "Belgium - Aalter", company: "OMCO Metals Belgium", street: "Venecolaan 10", city: "9880 Aalter", phones: ["T +32 9 374 20 05", "F +32 9 374 34 69"], email: ""},
	{color: "blue", country: "Croatia - Hum na Sutli", company: "OMCO Croatia d.o.o", street: "Hum na Sutli 107/5", city: "49231 Hum na Sutli", phones: ["T +385 49 327 327", "F +385 49 327 333"], email: ""},
	{color: "blue", country: "Romania - Iasi", company: "OMCO Romania SRL.", street: "Calea Chisinaului Street 43A", city: "700179 Iasi", phones: ["T +40 232 23 23 03", "T +40 232 23 23 71"], email: ""},
	{color: "blue", country: "United Kingdom - Leeds", company: "OMCO UK Ltd.", street: "New Street Mills Carlisle Rd", city: "Pudsey, Leeds LS28 8 LW", phones: ["T +44 113 25 73 172", "F +44 113 25 73 333"], email: ""},
	{color: "blue", country: "Turkey - Gebze", company: "OMCO Istanbul Kalip San. Ve Tic A.S.", street: "Dilovasi OSB Mah. Dilovasi Org.San.Bol.", city: "4. Kisim, D-4013 Sok. No:6 41455 Gebze/Kocaeli", phones: ["T +90 262 744 4452", "F +90 262 744 4456"], email: ""},	
	{color: "blue", country: "South Africa - Johannesburg", company: "OMCO South-Africa (Pty) Ltd", street: "8 Guthrie Road,", city: "Wadeville", phones: [""], email: ""},
	{color: "blue", country: "Hungary - Miskolc", company: "OMCO International Ltd", street: "Besenyői u. 10,", city: "Miskolc BAZ 3527", phones: [""], email: ""},
	{color: "bordeau", country: "Belgium - Sint-Truiden", company: "VCST Industrial Products BV", street: "Rellestraat 5030", city: "3800 Sint-Truiden", phones: ["T + 32 (0) 11 67 02 11"], email: "info@bmtdrivesolutions.com"},
	{color: "bordeau", country: "Belgium - Sint-Truiden", company: "Surface Treatment Company BV", street: "Nijverheidslaan 5420", city: "3800 Sint-Truiden", phones: ["T + 32 (0) 11 67 02 96"], email: "info@bmtdrivesolutions.com"},
	{color: "bordeau", country: "China - Changzhou", company: "VCST Automotive Components (Changzhou) Co, Ltd.", street: "8 Fushan Road, Wujin Economic Zone", city: "Changzhou, 213149", phones: ["T +86 519 8169 0996"], email: "info@bmtdrivesolutions.com"},
	{color: "bordeau", country: "Germany - Reichenbach", company: "VCST Reichenbach GmbH", street: "Am Fernblick 15", city: "08499 Mylau", phones: ["T + 49 (0) 37 65 795 – 0"], email: "info@bmtdrivesolutions.com"},
	{color: "bordeau", country: "Mexico - Leon", company: "VCST De Mexico S De R.L De C.V.", street: "Boulevard Aeropuerto No. 3202 Loc. A", city: "San Juan de Otates, C.P. 37670, León, Gto.", phones: ["T +52 477 152 6100"], email: "info@bmtdrivesolutions.com"},
	{color: "bordeau", country: "Romania - Alba", company: "SC VCST Automotive Production Alba SRL", street: "No. 9, Calea Ciugudului Street", city: "517240 Alba County, Alba Iulia", phones: ["T +40 372 133 700"], email: "info@bmtdrivesolutions.com"},
	{color: "bordeau", country: "USA - Zanesville", company: "IG Watteeuw USA LLC", street: "1000 Linden Avenue, Zanesville", city: "Ohio 43701 – 3098", phones: ["T +1 740 588 1722"], email: "info@bmtdrivesolutions.com"},	
	{color: "red", country: "Czech Republic - Brno", company: "IG Watteeuw ČR s.r.o.", street: "Vídeňská 130", city: "61900 Brno", phones: ["T +420 547 139 513"], email: "info@bmtdrivesolutions.com"},	
	{color: "red", country: "USA - Zanesville", company: "IG Watteeuw USA LLC", street: "1000 Linden Avenue, Zanesville", city: "Ohio 43701 – 3098", phones: ["T +1 740 588 1722"], email: "info@bmtdrivesolutions.com"},
	{color: "red", country: "India - Pune", company: "Involute Technologies Pvt. Ltd.", street: "Regd. off. Gat No. 232 & 156-159 Alandi – Market Road Dhanore", city: "Tal. Khed Dist. Pune 412 105", phones: ["T +91 982 255 9845"], email: "info@bmtdrivesolutions.com"},	
	{color: "red", country: "China - Suzhou", company: "IGW (Suzhou) Co., Ltd", street: "N° 1 Fenghe Road Fengting Avenue, SIP", city: "Suzhou 215122", phones: ["T +86 512 6287 5601"], email: "info@bmtdrivesolutions.com"},	
	{color: "yellow", country: "China - Suzhou", company: "IGW (Suzhou) Co., Ltd", street: "N° 1 Fenghe Road Fengting Avenue, SIP", city: "Suzhou 215122", phones: ["T +86 512 6287 5601"], email: "info@bmtdrivesolutions.com"},	
	{color: "yellow", country: "India - Pune", company: "Involute Technologies Pvt. Ltd.", street: "Regd. off. Gat No. 232 & 156-159 Alandi – Market Road Dhanore", city: "Tal. Khed Dist. Pune 412 105", phones: ["T +91 982 255 9845"], email: "info@bmtdrivesolutions.com"},	
	{color: "yellow", country: "Belgium - Oostkamp", company: "IG Watteeuw International nv", street: "Kampveldstraat 51", city: "8020 Oostkamp", phones: ["T +32 50 82 69 07"], email: "info@bmtdrivesolutions.com"},
	{color: "yellow", country: "Romania - Iasi", company: "IG Watteeuw Romania S.R.L.", street: "B-dul Chimiei nr. 6 Area B", city: "700291 Iasi", phones: ["T +40 374 100 235"], email: "info@bmtdrivesolutions.com"},
	{color: "yellow", country: "USA - Zanesville", company: "IG Watteeuw USA LLC", street: "1000 Linden Avenue, Zanesville", city: "Ohio 43701 – 3098", phones: ["T +1 740 588 1722"], email: "info@bmtdrivesolutions.com"},
	{color: "purple", country: "Belgium - Oostkamp", company: "BMT Aerospace International nv, BMT Eurair nv", street: "Handelsstraat 6", city: "8020 Oostkamp", phones: ["T +32 50 24 90 00", "F +32 50 24 90 10"], email: "info@bmtaerospace.com"},
	{color: "purple", country: "USA - Michigan", company: "BMT Aerospace USA Inc.", street: "18559 Malyn Blvd.", city: "Fraser, Michigan 48026 USA", phones: ["T +1 586 285 7700", "F +1 586 285 9720"], email: "info@bmtaerospace.com"},
	{color: "purple", country: "Romania - Iasi", company: "BMT Aerospace Romania srl", street: "Soseaua Iasi – Tomesti, 33B", city: "IASI 700292", phones: ["T +40 33 213 02 80"], email: "info@bmtaerospace.com"},
	{color: "purple", country: "Belgium - Aalter", company: "BMT Additive Belgium", street: "Venecolaan 10", city: "9880 Aalter", phones: ["T +32 9 374 20 05", "F +32 9 374 34 69"], email: ""},
]*/

const Page=() =>
{
const mainRef = useRef(null);
const history = useTransitionHistory();
var random = gsap.utils.random(-500, 500, true);
const [lastYear, setLastYear] = useState(null)

const firstRef = useRef(null);
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
const [state, setState] = useState({})
const [entries, setEntries] = useState([])

useTransition({
    handlers: [
      {
        path: '/bmt-global-presence',
        onEnter: async () => {
		await gsap.timeline().from("#comp1", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp2", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp3", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp4", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp5", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp6", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp7", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp8", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp9", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp10", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp11", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp12", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp13", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp22", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp14", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		}
      },
    ],
  });

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	if(lastYear)
    		history.push(`/${lastYear}`)
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push('/history');
	}
}

useEffect(() => {
	mainRef && mainRef.current &&
    	mainRef.current.focus();
}, [mainRef]);

function handleUp(e) {
    e.preventDefault();
    history.push(`/chart`);
}

const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/turnover.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let years = []
      data.forEach(x => 
        years.push(x.acf.year)
      )
      setLastYear(years[years.length - 1])
    }
  })

  const resp2 = await getData(`${process.env.PUBLIC_URL}/apidata/turnover.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let arr = []
      let years = []
      data.forEach(x => 
        years.push(x.acf.year)
      )

      data.forEach(x => 
        arr.push({...x.acf, years: years})
      )
      return arr[arr.length - 1]
    }
  })
  setState({...resp2})
}

useEffect(() => {
  fetchData()
}, []);

function createObj(department, color, arr)
{
	let obj = {}
	if(department.length > 1)
	{
	  	department.forEach((el) =>
	  		arr.push({color: color, country: el.country, company: el.company_name, street: el.address, city: el.postcode, phones: el.phones && [...el.phones.map(el => el.phone)], email: el.email})
	  	)
	}
	return obj
}

useEffect(() => {

let arr = []

const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/maps.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let obj = {}
      data.forEach(x => 
      {
        const {title, department} = x.acf
        if(title === "BMT Aerospace"){
          createObj(department, "purple", arr)
        }
        else if(title === "OMCO"){
          createObj(department, "blue", arr)
        }

        else if(title === "IGWI"){
          createObj(department, "yellow", arr)
        }
        else if(title === "VCST"){
          createObj(department, "bordeau", arr)
        }
      })
      return obj
    }
  })

  setEntries(arr)
}

fetchData();
}, []);

return(
<>
	<Header />

	{ isDesktop &&
	<div className={`${bc.vertical2}`}> 
	  <a href="/history">History / </a>
	  <span className={bc.destination}>Global Presence / </span>
  	  <a href={`/${state.year}`}>Turnover / </a>
	  <a href="/about-us-group-structure">Group Structure / </a>
	  <a href="/about-us-vision">Vision </a>
  	</div>
	}
	
	{ isDesktop &&
	<>	
	<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" className={`${styles.globalMapContainerTransparent}`}>	
	
		<a href={`/${lastYear}`} onClick={(e) => handleUp(e)}>
    		<img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)", height: "25px"}} alt="bmt aerospace arrows"/>
  		</a>

		<div className={`d-flex flex-row justify-content-center ${map.titleSpacing}`}>
			<h1 className={`${map.titleMaps} ${styles.blue} mt-2`}>BMT Global Presence</h1>	
		</div>

		<div className="d-flex flex-row justify-content-center" style={{minHeight: "70vh"}}>	
			<Globe className={map.globeSpacing} svgref={firstRef} style={{width: "80vw"}}/>
		</div>

		<a href="/history" className={map.refDownArrows}>
			<img src={arrows} alt="up arrows" style={{height: "25px", transform: "translateX(-50%)"}}/>		
		</a>
	</div>
	</>	
	}
	
	{	!isDesktop &&
	<>
		<div className="d-flex justify-content-center">
			<a href={`/${lastYear}`} onClick={(e) => handleUp(e)}>
	    		<img src={arrows} style={{transform: "rotate(180deg)", height: "25px", marginTop: "60px"}} alt="bmt aerospace arrows"/>
	  		</a>
  		</div>

		<div className={`d-flex flex-row mt-3 pl-3 ${map.titleMobile}`}>
			Global Presence
		</div>
		<div className={`d-flex flex-row align-items-center pl-3`}>
			<div className={`${map.mark} ${map.purple}`}></div>
			<div className={`${map.legend} ${map.purple} pl-1`}>BMT Aerospace</div>
		</div>
		<div className={`d-flex flex-row align-items-center pl-3`}>
			<div className={`${map.mark} ${map.blue}`}></div>
			<div className={`${map.legend} ${map.blue} pl-1`}>OMCO</div>
		</div>
		<div className={`d-flex flex-row align-items-center mt-2 pl-3`}>
			<div className={`${map.mark} ${map.bordeau}`}></div>
			<div className={`${map.legend} ${map.bordeau} pl-1`}>VCST</div>
		</div>
		<div className={`d-flex flex-row align-items-center mt-2 pl-3`}>
			<div className={`${map.mark} ${map.yellow}`}></div>
			<div className={`${map.legend} ${map.yellow} pl-1`}>IGW Industry</div>
		</div>


		{/*<div className={map.siteWrapper} style={{marginTop: "-100px"}}>
		<div className={map.fixed}>
			<div className={map.inner}>
				<Globe className={map.globeSpacing} 
				style={{minHeight: "100vh", minWidth: "300vw", overflow: "visible"}}/>
			</div>
		</div>
		</div>*/}

		<div className="col-md-10 mx-auto d-flex flex-column text-center justify-content-center align-items-center">
          {

          	entries && entries.map((x, i) => {
          		console.log(x)
          		return(
          		<div key={i} className={`${map.mapTooltip} ${x.color === "blue" ? map.blue : null} ${x.color === "red" ? map.red : null} ${x.color === "purple" ? map.purple : null} ${x.color === "yellow" ? map.yellow : null} ${x.color === "bordeau" ? map.bordeau : null}`}>
          			<div className={map.mapTooltipTitle}>{x.company}</div>
          			<div className={map.mapTooltipText}>{x.country}</div>
          			<div className={map.mapTooltipText}>{x.street}</div>
          			<div className={map.mapTooltipText}>{x.city}</div>
          			{
          				x.phones && x.phones.map((y, ii) => 
          					<div key={ii} className={map.mapTooltipText}>{y}</div>
          				)
          			}
          			<div className={map.mapTooltipText}>{x.email}</div>
          		</div>
          	)})
          }
        </div>


		<div style={{marginBottom: "40px"}}>
			<a href="/history" className={map.refDownArrowsGlobalMobile}>
				<img src={arrows} alt="up arrows" />		
			</a>
		</div>

	</>
	}
</>
)}

export default Page;